.c-newsitem {


    display: flex;
    background-color: white;
    margin-top: 2em;

    &__visual {
        position: relative;
        width: 40%;
        line-height: 0;

        &>img {
            filter: brightness(70%);
        }
    }

    &__date {
        position: absolute;
        text-transform: uppercase;
        top: 6em;
        left: 5%;
        color: white;
    }

    &__title {
        position: absolute;
        top: 5em;
        left: 5%;
        right: 5%;
        color: white;
        font-size: 1.6em;
        font-family: $font1;
        line-height: 130%;
    }

    &__text {
        width: 60%;
        padding: 3em;
    }
}

.newswrapper {
    background-color: $color3;
    padding: 4em 0;

    @media screen and (max-width: $width-tabletportrait) {
        display: none;
    }

    & h2 {
        margin-top: 0;
        margin-bottom: 2 em
    }

    & .meer {
        justify-self: center;
    }
}