.l-list {
	
	list-style-type: none;
	padding: 0;

	&--inline {
		@include ul-list-inline();
	}

	&__item {
		
	}

}

