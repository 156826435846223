/* ==|====================
   Module/Button
   ======================= */

.btn {

    @include border-radius(0px);
    @include inline-block();

    text-align: center;
    margin-top: 1em;
    vertical-align: middle;
    cursor: pointer;
    color: white;

    &:after {
        display: inline-block;
        content: '\00bb';
        margin: 0 .6em;
        color: white;
    }
}

@mixin btn($c1, $c2, $c3:none, $c4:2px) {
    @extend .btn;
    padding: .2em .7em;
    color: $c1 !important;
    border-width: $c4;
    border-style: solid;
    border-color: $c1;
    text-shadow: 0 1px 1px rgba(#000, 0.1);
    float: left;
    background-color: $c3;

    &:hover {
        background-color: $c2;
        color: white;
        border-color: $c2;
    }

    &:after {
        display: inline-block;
        content: '\00bb';
        margin: 0 .6em;
        color: $c1;
    }
}

.btn--form {
    border: white 1px solid;
    padding: 0.5em 1em;
    color: white;
    background-color: transparent;
    text-transform: uppercase;
    font-size: 0.9em;
}


.btn-border--c1 {
    @include btn($color1, $color2);
}

.btn-border--c2 {
    @include btn($color2, $color1);
}

.btn-border--w {
    @include btn(white, $color1);
}

.btn-full--c2 {
    @include btn(white, $color2, $color2, 0px);
    margin: 0;
}

.btn-full--c3 {
    @include btn($color1, $color3, $color3, 0px);
    text-transform: uppercase;
    padding: 0.5em 1em;
}