/* Splash layout */

.l-splash {

    &__callout {
        grid-column: 1/4;
    }

    &__content {
        grid-column: 2/4;
    }

    &__columns {
        columns: 2;
        column-gap: 2em;

        @media screen and (max-width: $width-tabletportrait) {
            columns: 1;
        }
    }
}