.c-map {
    height: 500px;
    width: 600px;

    & .title {
        font-weight: bold;
    }

    & a {
        text-decoration: underline;
    }
}
