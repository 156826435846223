/**
 * Modals ($modals)
 */

/* 1. Ensure this sits above everything when visible */
.modal {
    position: fixed;
    z-index: 10000;
    /* 1 */
    top: 0;
    left: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;
}

.modal.is-visible {
    visibility: visible;
}

.modal-overlay {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: hsla(0, 0%, 0%, 0.5);
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 0.3s, opacity 0.3s;
}

.modal.is-visible .modal-overlay {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
}

// .modal-body {
//     margin-top: -235px;
// }

.modal-wrapper {
    position: absolute;
    z-index: 9999;
    top: 0;
    left: calc((100vw - 1024px) / 2);
    width: 1024px;
    height: 100vh;
    max-height: 100%;
    background-color: #fff;
    box-shadow: 0 0 1.5em hsla(0, 0%, 0%, 0.35);

    @media screen and (max-width: $width-tabletportrait) {
        // left: calc((100vw - 325px)/2);
        // width: 325px;
        left: 0;
        width: 100%;
    }
}

.modal-transition {
    transition: all 0.3s 0.12s;
    transform: translateY(-10%);
    opacity: 0;
}

.modal.is-visible .modal-transition {
    transform: translateY(0);
    opacity: 1;
}

.modal-content {
    padding: 1em;
}

.modal-close {
    position: absolute;
    top: 0;
    left: 0;
    padding: 1em;
    color: #aaa;
    background: none;
    border: 0;

    & .icon-close {
        background-image: url('/site/templates/img/ico-close.svg');
        width: 32px;
        height: 32px;
    }
}

.modal-close:hover {
    color: #777;
}

.modal-body {
    min-height: 100%;
    height: 100%;
}

.modal-content > *:first-child {
    margin-top: 0;
}

.modal-content > *:last-child {
    margin-bottom: 0;
}
