/* ==|===============
   Module/Text formatted (rich content)
   ================== */

.c-wysiwyg {
    & > h1 {
        text-align: left;
        color: $color1;
        font-size: 2.2em;
        padding-bottom: 1em;
        text-transform: uppercase;
        padding-right: 1em;

        @media screen and (max-width: $width-smartphonelandscape) {
            font-size: 1.4em;
            padding-right: 0em;
        }
    }

    iframe {
        width: 100%;
    }

    .dates {
        font-size: 0.9em;
        margin-bottom: 2em;
    }

    & > h2 {
        font-size: 1.8em;
        margin: 2em 0 1em;
        text-transform: uppercase;
        padding-right: 1em;

        @media screen and (max-width: $width-smartphonelandscape) {
            font-size: 1.2em;
            padding-right: 0em;
        }
    }

    & > h3 {
        font-size: 1.2em;
        font-weight: 600;
        margin: 2em 0 0em;
        padding-right: 4em;
    }

    & > figure {
        margin: 2em 0 0 0;
    }

    figcaption {
        font-size: 0.8em;
        margin-bottom: 1.8em;
    }

    & > img {
        margin: 1.5em 0;
    }

    & > img {
        margin: 1.5em 0;
    }

    &a > img {
        margin: 1.5em 0;
    }

    ul.download-app {
        list-style-type: none;
        padding: 0 50% 0 0;
        margin: 1em 0 2em 0;
        width: 100%;
        float: left;

        @media screen and (max-width: $width-smartphonelandscape) {
            padding: 0 20% 0 0;
        }

        & > li {
            float: left;
            width: 50%;
        }
    }

    p,
    ul,
    ol {
        // text-align: justify;
        line-height: 1.5;
        padding-right: 2em; // margin-bottom: 3em;

        @media screen and (max-width: $width-tabletlandscape) {
            padding-right: 1em;
        }
    }

    & > div {
        // padding-right: 1em;
        columns: 2;
        margin: 3em 0;
        padding-right: 2em;

        @media screen and (max-width: $width-smartphonelandscape) {
            columns: 1;
            margin: 2em 0;
        }

        & > h2 {
            text-transform: none;
            margin-top: 0;
            padding-right: 3em;
            color: $color2;
        }

        & > h3 {
            font-size: 1.2em;
            font-weight: 600;
            margin: 2em 0 0em;
        }

        & > p {
            margin-bottom: 0;
        }
    }

    .groephuysman {
        margin-top: 4em;
        display: flex;
        // flex-wrap: wrap;
        justify-content: center;
        list-style-type: none;
        padding: 0;

        & > li {
            margin-right: 1em;
        }

        // & > li:last-child {
        //     margin-right: 0;
        // }
    }
}
