/* =======================
   Component/Contact us
   ======================= */

.c-contactus {
    position: fixed;
    right: 0;
    top: 230px;
    height: 70px;
    width: 250px;
    color: white;
    background-color: $color3;
    padding: 0.85em 1.2em;
    z-index: 15;

    @media screen and (max-width: $width-smartphonelandscape) {
        // padding: 0.5em 1.2em;
        // bottom: 0 !important;
        // top: auto;
        display: none;
    }



    & span {
        font-weight: 600;
    }

    div {
        position: absolute;
        top: 0;
        right: 0;
        width: 70px;
        border-left: white 1px solid;
        height: 70px;

        @media screen and (max-width: $width-smartphonelandscape) {
            height: 60px;
        }

        svg {
            width: 40px;
        }

        &>a {
            display: flex;
            height: 100%;
            padding: 1em;
        }
    }

    & a {
        color: white;




        & svg {
            @media screen and (max-width: $width-smartphonelandscape) {
                height: 60px;
            }
        }
    }

    @media screen and (max-width: $width-smartphonelandscape) {
        top: 0px;
        width: 100%;
        height: 60px;
    }
}