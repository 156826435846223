.l-constrained {

    @media screen and (max-width: $width-tabletlandscape) {
        grid-column: 2/4;
    }

    @media screen and (min-width: $width-tabletlandscape) {
        grid-column: 2/4;
    }

    &--master {
        position: relative;
        max-width: 1920px;
        margin: 0 auto;
        background-color: white;
    }

    &--small {
        position: relative;
        max-width: 1140px;
        margin: 0 auto;

        &.intro {
            margin-top: 4em;
        }
    }

    &--full {
        width: 100%;
    }

    &--flex {
        max-width: 1140px;
        margin: 0 auto;
        display: flex;
        justify-content: center;

        &.wrap {
            flex-direction: column;
        }

        &>section,
        &>article {
            max-width: 1140px;
            margin-bottom: 3em;
        }

        @media screen and (max-width: $width-tabletportrait) {
            flex-direction: column;
        }
    }
}