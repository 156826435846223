/* Splash section */

.c-splash {
	background: $color3;
	margin: 4em 0;
	padding: 2em 0;

	&__title {
		color: white;
	}

	&__text{
		
	}

}


