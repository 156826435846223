/* ===========================
   Base
   =========================== */

body {
    font-family: $font1;
    color: $color1;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.5;
    background-color: #e5e7e9;
    -webkit-font-smoothing: antialiased;
}

a {
    color: $color-link;
    text-decoration: none; // @include transition();
    &:hover {
        color: $color-link-hover;
    }
}

img,
embed,
object,
video {
    max-width: 100%;
    opacity: 1;
    transition: opacity 1s ease-in-out 0s;
}

img[data-sizes="auto"] {
    display: block;
    width: 100%;
}

::-moz-selection {
    background: $color1;
    color: #fff;
    text-shadow: none;
}

::selection {
    background: $color1;
    color: #fff;
    text-shadow: none;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}