/* beautify preserve:start */

/*
 * Inspired by https://sass-guidelin.es/#the-7-1-pattern
 * https://codepen.io/mor10/pen/xdYoNO
 */

/* Settings */

@import 'base/site-settings';

/* abstracts */

@import 'abstracts/mixins';
@import 'abstracts/functions';
@import 'abstracts/helpers';

/* Base */

@import 'base/site-settings';
@import 'base/normalize';
@import 'base/base';
@import 'base/form';

/* Layout */

@import 'layout/header';
@import 'layout/grid';
@import 'layout/wrapper';
@import 'layout/aside';
@import 'layout/main-nav';
@import 'layout/constrained';
@import 'layout/splash';
@import 'layout/cards';
@import 'layout/list';
@import 'layout/chloe';
@import 'layout/newsletter-archive';
@import 'layout/search';
@import 'layout/gallery';

/* Components */

@import 'components/hero-header';
@import 'components/cards';
@import 'components/gradients';
@import 'components/icons';
@import 'components/btn';
@import 'components/footer';
@import 'components/headings';
@import 'components/breadcrumb';
@import 'components/brd';
@import 'components/table';
@import 'components/form';
@import 'components/wysiwyg';
@import 'components/splash';
@import 'components/twins';
@import 'components/search';
@import 'components/formbuilder';
@import 'components/main-nav';
@import 'components/nav-selector';
@import 'components/news';
@import 'components/people';
@import 'components/modal';
@import 'components/contactus';
@import 'components/map';
@import 'components/mccf';
@import 'components/praktischeinfo';
@import 'components/pagernav';

/* Pages */

@import 'pages/home';

/* States */

@import 'state';

/* beautify preserve:end */
