/* ==|====================
   Layout/Breadcrumbs
   ======================= */


/* Style the list */

ul.c-breadcrumbs {
    padding: 24px 16px;
    list-style: none;
    margin: 0; // background-color: white;
    & li {
        display: inline;
    }

    & li+li:before {
        padding: 8px;
        color: $color2;
        content: "/\00a0";
    }

    & li a {
        color: $color2;
        text-decoration: none;
    }

    & li a:hover {
        color: $color2;
        text-decoration: underline;
    }
}