/* ==|====================
   Module/Icons
   Documentation : https://codepen.io/noahblon/pen/qEByrR
   <a href=""><i class="icon icon-facebook">Facebook Icon</i></a>
   ======================= */

@function _buildIcon($icon) {
    $icon: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30" height="20">#{$icon}</svg>';
    @return $icon;
}

@function icon( $icon-name,
$color,
$stroke-color: transparent,
$stroke-width: 0,
$css: '' // arbitrary css
) {
    $icons: ( facebook: '<path fill="#{$color}" stroke="#{$stroke-color}" stroke-width="#{$stroke-width}" style="#{$css}" d="M10.666 10.868v-3.7h2.715V5.725C13.381 3.239 15.248 1 17.543 1h2.991v3.701h-2.991c-.328 0-.709.397-.709.992v1.475h3.7v3.7h-3.7v8.353h-3.453v-8.353h-2.715z"/>',
    linkedin: '<path fill="#{$color}" stroke="#{$stroke-color}" stroke-width="#{$stroke-width}" style="#{$css}" d="M21.248 21.258h-4.365v-7.439c0-2.04-.776-3.18-2.389-3.18-1.756 0-2.673 1.186-2.673 3.18v7.439H7.614V7.086h4.207v1.909s1.265-2.342 4.271-2.342c3.004 0 5.156 1.836 5.156 5.632v8.973zM2.594 5.23A2.604 2.604 0 0 1 0 2.615 2.604 2.604 0 0 1 2.594 0a2.604 2.604 0 0 1 2.594 2.615A2.604 2.604 0 0 1 2.594 5.23m2.215 16.028H.422V7.086h4.387v14.172z"/>',
    twitter: '<path fill="#{$color}" stroke="#{$stroke-color}" stroke-width="#{$stroke-width}" style="#{$css}" d="M20.018 4.514c.289 6.434-4.51 13.61-13.004 13.61-2.584 0-4.99-.758-7.014-2.057a9.197 9.197 0 0 0 6.774-1.894 4.58 4.58 0 0 1-4.275-3.178 4.563 4.563 0 0 0 2.067-.078C2.365 10.476.846 8.493.896 6.374a4.567 4.567 0 0 0 2.072.572A4.582 4.582 0 0 1 1.552.837a12.99 12.99 0 0 0 9.43 4.781C10.316 2.756 12.486 0 15.44 0a4.57 4.57 0 0 1 3.339 1.445A9.18 9.18 0 0 0 21.685.334a4.596 4.596 0 0 1-2.012 2.532 9.12 9.12 0 0 0 2.627-.721 9.28 9.28 0 0 1-2.282 2.369"/>',
    mail: '<g fill="#{$color}" stroke="#{$stroke-color}" stroke-width="#{$stroke-width}" style="#{$css}"><path d="M15.131 12.816l13.631-6.79V.62H1.5v5.406l13.631 6.79z"/><path d="M15.131 15.089L1.5 8.298v11.138h27.262V8.298l-13.631 6.791z"/></g>',
    verzekeringen: '<g fill="#{$color}" stroke="#{$stroke-color}" stroke-width="#{$stroke-width}" style="#{$css}"><path d="M22.878 12.155v1.199l-.002.074h.002v2.193h1.289v-2.073a2.863 2.863 0 0 0-1.289-1.393"/><path d="M24.308 12.843l-.02.049.02-.049m.043-.098c-.008.016-.016.031-.022.047.006-.016.014-.031.022-.047m.048-.095l-.025.046.025-.046M24.512 12.468l-.033.048.033-.048m.064-.086l-.038.049.038-.049m.07-.083c-.015.017-.031.034-.046.053.015-.019.031-.036.046-.053m.073-.078a1.376 1.376 0 0 0-.053.055l.053-.055m-.449.725l-.017.05.017-.05M24.797 12.147l-.062.058.062-.058m1.654-.199c-.404-.362-1.064-.272-1.57.128.513-.406 1.183-.494 1.586-.115a2.161 2.161 0 0 0-1.529-.617c-.262 0-.525.047-.771.14V9.537h-1.289v2.618c.427.225.953.641 1.289 1.393v-.12c0-.068.011-.194.057-.326l.014-.05-.014.05-.001.005c.076-.225.264-.468.716-.468.083 0 .814.022.814.789v2.193h1.295v-2.193c0-.581-.213-1.1-.597-1.48"/><path d="M17.604 7.52V3.891h14.425M32.029 3.891v9.579c0 5.616-7.213 8.395-7.213 8.395s-2.91-1.121-5.033-3.378"/><path d="M24.804 21.633l7.15-9.695M24.804 21.633l7.15-9.695M30.78 17.286s1.263-7.423-8.009-13.395"/><path d="M2.029 28.826h24.775M4.359 9.537h13.245v19.288H4.359zM6.528 11.379v1.178M6.528 14.35v1.178M6.528 17.322V18.5M6.528 20.294v1.177M6.528 25.864v1.178M9.343 11.379v1.178M9.343 14.35v1.178M9.343 17.322V18.5M9.343 20.294v1.177M9.343 25.864v1.178M12.159 11.379v1.178M12.159 14.35v1.178M12.159 17.322V18.5M12.159 20.294v1.177M14.974 11.379v1.178M14.974 14.35v1.178M14.974 17.322V18.5M14.974 20.294v1.177M19.544 25.864v1.178M22.118 25.864v1.178M18.677 9.537H3.015M25.579 24.036H9.585M4.359 8.114h8.782v1.423H4.359zM12.159 24.058v4.79M15.433 24.058v4.79"/><path d="M13.806 24.058v4.379"/><path d="M24.189 24.058v4.379M6.115 8.114V6.203"/></g>');

    $icon: _buildIcon(map-get($icons, $icon-name));
    @return url('data:image/svg+xml;utf8,#{$icon}');
}


.icon {
    display: inline-block;
    font-size: 0;
    width: 30px;
    height: 20px;
    margin: 0 auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.icon-lg {
    width: 75px;
    height: 75px;
}

.icon-facebook {
    background-image: icon(facebook, white);
}

.icon-linkedin {
    background-image: icon(linkedin, white);
}

.icon-twitter {
    background-image: icon(twitter, white);
}

.icon-mail {
    background-image: icon(mail, white);
}

.icon-verzekeringen {
    background-image: icon(verzekeringen, white);
}


.icon-facebook-stroke {
    background-image: icon(facebook, transparent, blue, 1);
}

.icon-linkedin-stroke {
    background-image: icon(linkedin, transparent, blue, 1);
}