.l-chloe-cta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #e5e7e9;
    border-bottom: 1px solid #e5e7e9;
    padding: 1em 2em;
    margin-top: 3em;

    &--wrapper {
        flex: 0 1 auto;
        padding: 2em;
    }

    & h3 {
        color: $color2;
        font-size: 1em;
        text-transform: uppercase;
    }

    & p {
        font-weight: bold;
        font-size: 1.3em;
        padding: 0;
    }

    a {
        flex: 0 0 auto;
        color: white;
        background-color: $color2;
        padding: 0.7em 1.5em;

        &:hover {
            background-color: $color1;
        }
    }

    @media screen and (max-width: $width-tabletportrait) {
        display: none;
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
    }
}
