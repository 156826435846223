/* ==|====================
   Layout/Header
   ======================= */

.l-header {

    background-color: white;

    .site-title {
        margin-top: -1em;
        margin-bottom: 3em;
    }
}