%gradient {
    background: linear-gradient(to bottom right, $color2, $color4);
    justify-content: center;
    align-items: center;
}

.gradient--circle {
    flex: 0 1 auto;
    display: flex;

    width: 160px;
    height: 160px;
    border-radius: 50%;
    @extend %gradient;

    &>svg {
        width: 75%;
        height: 75%;
    }
}


.gradient--rectangle {
    @extend %gradient;
}