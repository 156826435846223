/* ======================
  Component / People
   ======================= */

.c-people {
    border-top: 1px solid $color3; // border-bottom: 1px solid $color3;
    padding-top: 3em;
    padding-bottom: 2em;

    @media screen and (max-width: $width-laptop) {
        padding: 0 1em;
    }

    p {
        margin-top: 0;
        margin-bottom: 1em;
        text-align: initial;
        padding-right: 1em;

        @media screen and (max-width: $width-smartphonelandscape) {
            text-align: center;
        }
    }

    h2 {
        margin-bottom: 2em;
    }

    a {
        text-decoration: underline;
        color: $color1;

        &:hover {
            color: $color2;
        }
    }

    &__title {
        display: block;
        margin-top: 1em;
        margin-bottom: 0;
        font-weight: 600;
        letter-spacing: -1px;
        width: 90%;
    }

    .circle {
        border-radius: 50%;
        width: 150px;
    }

    &__grid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    &__person {
        flex: 0 1 30%; // margin-right: 3em;
        margin-bottom: 2em;

        @media screen and (max-width: $width-tabletportrait) {
            margin-right: 0em;
        }

        @media screen and (max-width: $width-smartphonelandscape) {
            flex: 0 1 100%;
            text-align: center;
        }
    }
}
