/* ==|====================
   Layout/Cards
   ======================= */

.c-card {

    margin-bottom: 1em;

    &--half {
        // flex: 0 1 50%;
        // flex: 1;
        float: left;
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1em;
        margin-top: 2em;

        &>p {
            max-width: 100%;
            color: white;
            padding: 0 10%;
            text-align: center;
        }
    }

    &--third {
        // flex: 0 1 33%;
        // flex: 1;
        float: left;
        width: 33%;

        @media screen and (max-width: $width-smartphonelandscape) {
            width: 100%;
        }
    }

    margin-bottom: 2em;

    @media screen and (max-width: $width-smartphonelandscape) {
        margin-bottom: 0em;
    }

    &--grid {
        padding: 0em;
    }

    &--grid:nth-child(odd) {
        padding-right: 0.5em;
    }

    &--grid:nth-child(even) {
        padding-left: 0.5em;
    }

    &__title {
        font-size: 1em;
        color: white;
        text-align: center;
        font-weight: 400;
        margin: 0 1em;

        &--grid {
            color: $color1;
            margin-top: 0.5em;
            line-height: 1.5;
            text-transform: none;
        }

        &--abs {
            position: absolute;
            bottom: 40%;
            width: 100%;
            margin: 0;
            padding: 0 1em;
        }
    }



    &__date {
        font-size: 0.80em;
        margin-top: 1em;
    }

    &__sum {
        color: $color1;
    }


    & li {
        text-align: center;
        background-color: #F4F4F4;
        padding: 0em;
        margin-bottom: 0.75em;

        &:hover {
            background-color: $color2;

            &>a {
                color: white;
                text-decoration: none;

            }
        }

        &>a {
            color: $color1;
            text-transform: uppercase;
            font-weight: 400;
            padding: 0.75em;
            display: block;
        }
    }


    &__image {
        position: relative;
        line-height: 0;
        width: 100%;
    }

    &__text {
        color: $color2;
    }

    a {
        color: $color2;
    }

    a:hover {
        text-decoration: underline;
    }

    /* autoprefixer: ignore next */
    .svg-clipped-catone {
        clip-path: url(#svgPathOne);

        @media screen and (max-width: $width-smartphonelandscape) {
            clip-path: none;
        }
    }

    .svg-clipped-cattwo {
        clip-path: url(#svgPathTwo);

        @media screen and (max-width: $width-smartphonelandscape) {
            clip-path: none;
        }
    }

    .svg-clipped-cattree {
        clip-path: url(#svgPathTree);

        @media screen and (max-width: $width-smartphonelandscape) {
            clip-path: none;
        }
    }
}




//********** Breakpoints ************** //
@media screen and (max-width: $width-tabletportrait) {

    .c-card {
        // flex: 0 1 calc(50% - 1em);
    }
}


@media screen and (max-width: $width-smartphonelandscape) {

    .c-card {
        flex: 0 1 100%;
    }
}