@media all and (min-width: 600px) {
    .aside {
        flex: 1 auto;
    }
}


.aside {
    will-change: min-height;
}

.is-affixed {
    // margin-top: 100px;
}


.aside__inner {
    transform: translate(0, 0);
    /* For browsers don't support translate3d. */
    transform: translate3d(0, 0, 0);
    will-change: position, transform;
}

.aside--form {
    z-index: 1; // background-color: $color3;
    // padding: 1em;
    flex: 0 0 35%;

    @media screen and (max-width: $width-tabletportrait) {
        flex-basis: 40%;
        padding-right: 1em;
        padding-bottom: 1em;
    }
}

.aside--form-bg {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    background-color: $color3;
    opacity: 0;
}


.main {
    flex: 3 0px;
    flex-basis: 65%; // padding-bottom: 3em;

    @media screen and (max-width: $width-tabletportrait) {
        flex-basis: 60%;
    }
}

.aside--form {
    order: 2;
}

.main {
    order: 1;
}