@charset "UTF-8";

// ----------------------
// == Site settings
// ----------------------

// Html 5 Element
$audio-canvas-video: false; // True | False

// Fonts
$font1: 'Montserrat', sans-serif;

// = Colors
// -----------------------------

// Graphic chart
$red: #ed1e48;
$blue: #314550;
$facebook: #3b5998;
$twitter: #43bfe1;
$brown: #fff6e5;
$lightgray: #ededed;
$orange: #f26b48;

$color1: $blue;
$color2: $red;
$color3: $lightgray;
$color4: $orange;

// Text
$color-text-base: #212121;
$baseline: 0.5em;

// link
$color-link: $color2;
$color-link-hover: darken($color2, 10%);

// Mixin Placeholder
$color-mixin-placeholder: #cdcdcd;

// Selection
$color-selection: $color1;

// Btn
$bg-btn: #555;
$bg-btn-hover: darken($bg-btn, 10%);
$clr-btn: #212121;
$clr-btn-hover: darken($clr-btn, 10%);

//Grid
$total-columns: 12;

// = Prefix
// -----------------------------

$prefix-for-webkit: true !default;
$prefix-for-mozilla: true !default;
$prefix-for-microsoft: true !default;
$prefix-for-opera: true !default;
$prefix-for-spec: true !default; // required for keyframe mixin

// = Breakpoints
// -----------------------------

$width-desktop: 100em; // 1600/16
$width-laptop: 80em; // 1280/16
$width-tabletlandscape: 64em; // 1024/16
$width-tabletportrait: 48em; // 768/16
$width-smartphonelandscape: 40em; // 640/16
$width-smartphoneportrait: 25.875em; // 375/16
