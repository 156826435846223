/* ======================
   Module/Cookie consent
   ======================= */

.l-cookies-eu-banner {
    background-color: $color1;
    color: white;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 20;
    padding: 0.5em 2em;

    .wrapper {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: $width-smartphoneportrait) {
            flex-direction: column;
        }
    }

    & button {
        display: flex;
        align-self: center;
        flex: 0 1 300px;
        background: linear-gradient(to bottom right, $color2, $color4);
        color: white;
        border: none;
        align-items: center;
        padding: 0.3em 1em;
        margin-left: 1em;
    }

    & div {
        display: flex;
        // flex-direction: column;
    }

    & p {
        flex: 0 1 auto;
        margin: 0.5em;

        @media screen and (max-width: $width-smartphoneportrait) {
            font-size: 0.8em;
            text-align: center;
            margin-bottom: 1em;
        }
    }
}
