/* ==|====================
   Modules/Grid
   ======================= */


@mixin display-grid {
    display: grid;
    grid-template-rows: auto;
    // grid-gap: 1.5em;
}

.grid {
    @include display-grid;
    // 4 kolommen waarvan kol 2 & 3 max 35em zijn. Een kolom is minimaal 1 em breed

}

.block {
    margin-top: 4em;
    margin-bottom: 4em;
}

%grid-helpers-base {
    @media screen and (min-width: $width-tabletlandscape) {
        @include display-grid;
        grid-template-columns: 1fr;
    }
}

.cols-1 {
    @extend %grid-helpers-base;

    @media screen and (min-width: $width-tabletlandscape) {
        grid-template-columns: 70% 1fr;
    }
}

.cols-2 {
    @extend %grid-helpers-base;

    @media screen and (min-width: $width-tabletlandscape) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.cols-3 {
    @extend %grid-helpers-base;

    @media screen and (min-width: $width-tabletlandscape) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.cols-4 {
    @extend %grid-helpers-base;

    @media screen and (min-width: $width-tabletlandscape) {
        // grid-template-columns: repeat(4, 1fr);
        grid-template-columns: 1fr repeat(2, minmax(auto, 35em)) 1fr;
    }
}

.cols-auto-fit {
    @extend %grid-helpers-base;

    @media screen and (min-width: $width-tabletlandscape) {
        grid-template-columns: repeat(auto-fit, minmax(15.625em, 1fr));
    }
}


.no-cssgrid {
    .cols-2 {
        @media screen and (max-width: $width-tabletlandscape) {
            >* {
                width: 50%;
                padding-right: $baseline*3;
                float: left;
                margin-bottom: $baseline*3;
            }
        }
    }

    .cols-3 {
        @media screen and (max-width: $width-tabletlandscape) {
            >* {
                width: 33.3%;
                padding-right: $baseline*3;
                float: left;
                margin-bottom: $baseline*3;
            }
        }
    }

    .cols-4 {
        @media screen and (max-width: $width-tabletlandscape) {
            >* {
                width: 25%;
                padding-right: $baseline*3;
                float: left;
                margin-bottom: $baseline*3;
            }
        }
    }

    .cols-auto-fit {
        @media screen and (max-width: $width-tabletlandscape) {
            text-align: center;

            >* {
                min-width: 15.625em;
                padding-right: $baseline*3;
                display: inline-block;
                margin-bottom: $baseline*3;
            }
        }
    }
}

//********** Breakpoints ************** //

@media screen and (min-width: $width-desktop) {}

@media screen and (min-width: $width-tabletlandscape) {}


@media screen and (max-width: $width-tabletlandscape) {}


@media screen and (max-width: $width-tabletportrait) {}


@media screen and (max-width: $width-smartphoneportrait) {}