.l-cards {
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: $width-smartphonelandscape) {
        flex-direction: column;
        margin-top: 3em;
    }

    .l-cards__content {
        padding: 2em;
    }

    &--nowrap {
        flex-wrap: nowrap;
    }

    &--grid {
        display: flex;
        flex-wrap: wrap;
        margin: 4em 0;
        padding: 0 3em 0 0;

        @media screen and (max-width: $width-laptop) {
            padding: 0 1em 0 0;
        }
    }
}