/* ==|=====================
   Module/Table
   ======================== */

table {
    @include border-radius(2px);
    font-size: .875em;
    /* 14/16 */
    width: 100%;

    th {
        font-family: $font1;
        font-weight: bold;
        color: $color1;
        padding: .5em .5em .5em 0;
        text-transform: uppercase;
        border-top: 2px solid $color1;
        border-bottom: 1px solid $color1;
        text-align: left;

        &:first-child {
            padding-left: 1.2em;
            @include border-radius(2px 0 0 0);
        }

        &:last-child {
            @include border-radius(0 2px 0 0);
        }
    }

    tr {
        td {
            border-bottom: 1px dashed #d4d2d2;
            padding: .7em 3em .7em 0.7em;

            &:first-child {
                padding-left: 1.2em;
            }

            &:last-child {
                padding-right: .7em;
            }
        }

        &:last-child td {
            border-bottom: none;
        }
    }

    /* Medium Screens */
    @media (max-width: 54em) {
        font-size: .8em;

        tr td {
            padding-right: 2em;
        }
    }
}