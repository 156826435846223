/* ==|================
Module/Form
=================== */

.c-form {
    padding: 2em 2em 2em 2em;
    color: white;

    h3 {
        color: white;
    }

    label {
        color: white;
    }

    input {
        color: white;
        background-color: transparent;
        border: none;
        box-shadow: unset;
        border-bottom: 1px solid white;
        padding-left: 0;
    }

    &__info {
        width: 80%;
        margin-bottom: 2em;
    }

    .privacy {
        display: block;
        color: white;
        margin-top: 2em;
        font-size: 0.9em;
        text-decoration: underline;
    }

    ul {
        list-style-type: none;
        width: 100%;
        padding: 0;
        display: inline-block;
        margin: 1em 0 !important;

        &>li {
            float: left;
            width: 100%;
        }
    }

    .InputfieldStateShowIf {
        display: none;
    }

    .progress-bar {
        background-color: white;
        font-size: 0.75em;
        padding-left: 1em;
        color: $color1;
    }

     ::-webkit-search-decoration {
        display: none;
    }

    input:focus {
        // outline: 0;
    }
    button {
        border: 1px solid white;
        background-color: transparent;
        padding: 6px 12px;
    }

    fieldset {
        border: 0;
        padding: 0.01em 0 0 0;
        margin: 0;
        min-width: 0;
    }

    fieldset:not(:first-of-type) {
        display: none;
    }

    &--mailchimp {
        // text-align: center;
        button {
            margin-left: 2em;
        }
    }


    &--search {
        input[type="text"],
        .formt-text {
            @include border-radius(2px 0 0 2px);
            border-right: none;
        }
        .btn {
            @include border-radius(0 2px 2px 0);
            vertical-align: top;
            padding: .35em .6em;
        }
    }

    input.error,
    select.error,
    textarea.error {
        outline: none;
        border-color: white;
        border-width: 1px;
        border-style: strong;
        background-color: #ef9b92;
        color: $color1;
        &:valid {
            border-style: auto;
            border-color: inherit;
            background-color: transparent;
            color: white;
        }
    }
}