body.search {
    margin-left: 0em;
}

.l-search {
    &__results {
        list-style: none;
        padding: 0 2em 0 0;
        margin: 0;

        & > li {
            margin-bottom: 2em;
        }
    }

    &__title {
        color: red;
    }

    &__link {
        color: #1a0dab;
        font-size: 1.2em;
    }

    &__summary {
        font-size: 1em;
        color: #4d5156;
    }

    &__url {
        font-size: 0.8em;
        margin-bottom: 0.2em;
    }
}

.search {
    margin-left: 1em;

    @media screen and (max-width: $width-smartphonelandscape) {
        margin-bottom: 2em;
    }
}

.search__wrapper {
    position: relative;

    @media screen and (max-width: $width-smartphonelandscape) {
        flex-direction: column;
    }
}

.search > form {
    position: relative;

    & > input {
        box-shadow: none;
    }
}

.search .search__btn {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    outline: none;
}

.search--animated .search__input {
    color: white;
    width: 28px;
    height: 28px;
    padding: 0 24px;
    border: 0;
    border-radius: 0;
    background: none;
    cursor: pointer;
    transition: width 0.3s 0s;
}

.search--animated .search__btn {
    width: 28px;
    height: 28px;
    margin-left: -24px;
    border: 1px solid white;
    border-radius: 24px;
    transform: rotate(45deg);
    pointer-events: none;
    position: absolute;
    left: 50%;
    top: 0;
    z-index: 10;
    transition: width 0.3s 0s, transform 0.3s 0.6s, left 0.3s 0.3s,
        margin 0.3s 0.3s;
    overflow: visible;
}

.search--animated .search__btn:after {
    content: '';
    width: 10px;
    height: 1px;
    background-color: white;
    position: absolute;
    left: 100%;
    top: 50%;
    margin-top: -1px;
    transition: width 0.3s 0.3s;
}

.effect-preview:hover .search--animated .search__input,
.search--animated .search__input:focus {
    width: 240px;
    cursor: text;
    transition: width 0.3s 0.6s;

    @media screen and (max-width: $width-smartphonelandscape) {
        width: 100%;
    }
}

.effect-preview:hover .search--animated .search__btn,
.search--animated .search__input:focus + .search__btn {
    width: 240px;
    transform: rotate(0deg);
    left: 0;
    margin-left: 0;
    transition: width 0.3s 0.6s, transform 0.3s 0s, left 0.3s 0.6s,
        margin 0.3s 0.6s;
}

.effect-preview:hover .search--animated .search__btn:after,
.search--animated .search__input:focus + .search__btn:after {
    width: 0;
    transition: width 0.3s 0.3s;
}
