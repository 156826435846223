.is-uppercase {
    text-transform: uppercase;
}

.has-nomargin {
    margin: 0;
}

.has-nopadding {
    padding: 0;
}

.has-padding-3 {
    padding: 3em;
}

.has-nmt {
    margin-top: 0 !important;
}

.has-nmb {
    margin-bottom: 0 !important;
}

.has-mt-1 {
    margin-top: 1em;
}

.has-mb-1 {
    margin-bottom: 1em !important;
}

.has-mt-3 {
    margin-top: 3em;
}

.has-mtb-1 {
    margin-top: 1em;
    margin-bottom: 1em;
}

.has-pl-1 {
    padding-left: 1em;
}

.has-plr-1 {
    padding-left: 1em;
    padding-right: 1em;
}

.has-ptp-2 {
    padding-top: 2em;
    padding-bottom: 2em;
}

.has-ptp-4 {
    padding-top: 4em;
    padding-bottom: 2em;
}

.has-mb-4 {
    margin-bottom: 4em;
}

.has-no-gap {
    grid-gap: 0
}

.is-ib {
    @include inline-block;
}