.l-gallery {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    grid-auto-rows: 120px;
    grid-auto-flow: dense;
    margin-top: 4em;

    & > a {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2em;
        padding: 0.5em 0.5em 0.5em 0em;
    }

    & > a > img {
        width: 100%;
        height: 100%;
        // object-fit: cover;
    }
}
