/* =======================
   Layout/Nav selector
   ======================= */

.c-nav-selector {

    margin: 2em 0;

    &>ul {
        padding: 0;
        list-style: none;
        margin: 0;
        display: flex;
        flex-flow: row wrap;
    }

    & li {
        padding: 0.5em 1em;
        background-color: $color3;
        margin-right: 2px;
    }

    & a {
        text-decoration: none;
        display: flex;
        align-items: center;
        color: $color1;
        text-align: center;
        padding: 0px;

        &>img {
            margin-right: 0.5em;
        }
    }

    & li.active,
    li:hover {
        background: linear-gradient(to bottom right, $color2, $color4);
        justify-content: center;
        align-items: center;
        &>a {
            color: white;
        }
    }
}