/* ==|======================
   Layout/Home
   ========================= */

.home {
    .c-card__title {
        font-size: 1.5em;
        padding: 0 1.5em;
        margin: 2em 0 0 0;
    }

    .initiaal {
        top: 20px;
        position: relative;
        margin: 0 0.5em;
    }

    .intro,
    .mailinglist {
        @media screen and (max-width: $width-smartphonelandscape) {
            display: none;
        }
    }

    & .menu-mobile {
        @media screen and (min-width: $width-smartphonelandscape) {
            display: none;
        }
    }

    & .c-hero-header__navbg {
        background: rgba(6, 6, 6, 0.4);
    }

    & .cloned .c-hero-header__navbg {
        background: $color3;
    }

    .usp {
        @media screen and (max-width: $width-tabletportrait) {
            display: none;
        }
    }

    & .c-main-nav {
        background-color: transparent;

        & .l-wrapper:first-child {
            // background: rgba(168, 142, 97, 0.4);
        }

        &.sticked {
            background-color: white;

            & .l-wrapper:first-child {
                background-color: $color3;
            }

            & a {
                color: $color1;
            }

            .current {
                background-color: white;
            }
        }

        .current {
            background-color: rgba(79, 72, 62, 0.5);
        }

        a {
            color: white;
            font-size: 0.9em;
            text-transform: uppercase;
            font-weight: 600;
            // padding: 0.75em 1em;
            display: inline-block;
            letter-spacing: 1px;
        }
    }
}
