@mixin display-flex {
    display: flex;
    align-items: center;
}


.l-wrapper {

    position: relative;
    z-index: 1;

    &--flex {
        @include display-flex;
        flex-flow: row wrap;
    }

    &--flex>* {
        padding: 1em;
        flex: 1 100%;
    }




    @media screen and (max-width: $width-tabletlandscape) {
        @include display-flex;
    }

    @media screen and (min-width: $width-tabletlandscape) {
        @include display-flex;

    }

}