// =======================
// Tools/Mixins
// Thanks to @sutterlity
// https://github.com/sutter/POPY-Starter-integration-web/blob/master/scss/tools/_mixins.scss
// =======================
// -----------------------
// == Base
// -----------------------
// = Inline-block
// -----------------------------
@mixin inline-block ($haslayout: true) {
    display: inline-block;
    vertical-align: middle;
    @if $haslayout==true {
        .lt-ie8 & {
            display: inline;
            zoom: 1;
        }
    }
}

// = Gradient
// -----------------------------
@mixin gradient ($haslayout: true) {
    background: linear-gradient(to bottom right, $color2, $color4);
}

// = Underline
// -----------------------------
@mixin underline ($color, $position) {
    display: inline-block;
    padding-bottom: 0.75em;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        height: 1px;
        bottom: 0;

        @if ($position==left) {
            width: 75px;
            left: 0;
        }
        @else if $position==center {
            width: 25%;
            left: 37.5%;
        }
        border-bottom: 1px solid $color;
    }
}

// = Clearfix
// -----------------------------
@mixin clearfix ($haslayout: true) {
    @if $haslayout==true {
        .lt-ie8 & {
            zoom: 1;
        }
    }
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both
    }
}

// = Opacity
// -----------------------------
@mixin opacity($opacity) {
    filter: unquote("progid:DXImageTransform.Microsoft.Alpha(Opacity=#{round($opacity * 100)})");
    opacity: $opacity;

    /* Hack IE6 */
    .lt-ie7 & {
        filter: alpha(opacity=#{round($opacity * 100)});
        zoom: 1;
    }
}

// = Placeholder
// -----------------------------
@mixin placeholder($color: $clr-mixin-placeholder) {
    &::-webkit-input-placeholder {
        color: $color;
    }
    &:-moz-placeholder {
        color: $color;
    }
    &:-ms-input-placeholder {
        color: $color;
    }
}

// = Selection
// -----------------------------
$prefixes: ("-webkit-", "-moz-", "");
@mixin selection($color, $background) {
    @each $prefix in $prefixes {
         ::#{$prefix}selection {
            color: $color;
            background: $background;
            text-shadow: none;
        }
    }
}

// = Position
// -----------------------------
@mixin position ($position: relative, $coordinates: 0 0 0 0) {
    @if type-of($position)==list {
        $coordinates: $position;
        $position: relative;
    }
    $top: nth($coordinates, 1);
    $right: nth($coordinates, 2);
    $bottom: nth($coordinates, 3);
    $left: nth($coordinates, 4);
    position: $position;
    @if $top==auto {
        top: $top;
    }
    @else if not(unitless($top)) {
        top: $top;
    }
    @if $right==auto {
        right: $right;
    }
    @else if not(unitless($right)) {
        right: $right;
    }
    @if $bottom==auto {
        bottom: $bottom;
    }
    @else if not(unitless($bottom)) {
        bottom: $bottom;
    }
    @if $left==auto {
        left: $left;
    }
    @else if not(unitless($left)) {
        left: $left;
    }
}

// ----------------------------------------------------------
// == CSS3
// ----------------------------------------------------------
// = Prefixer
// -----------------------------
$prefix-for-webkit: true !default;
$prefix-for-mozilla: true !default;
$prefix-for-microsoft: true !default;
$prefix-for-opera: true !default;
$prefix-for-spec: true !default;

@mixin prefixer ($property, $value, $prefixes) {
    @each $prefix in $prefixes {

        @if $prefix==webkit and $prefix-for-webkit==true {
            -webkit-#{$property}: $value;
        }
        @else if $prefix==moz and $prefix-for-mozilla==true {
            -moz-#{$property}: $value;
        }
        @else if $prefix==ms and $prefix-for-microsoft==true {
            -ms-#{$property}: $value;
        }
        @else if $prefix==o and $prefix-for-opera==true {
            -o-#{$property}: $value;
        }
        @else if $prefix==spec and $prefix-for-spec==true {
            #{$property}: $value;
        }
        @else {
            @warn "Unrecognized prefix: #{$prefix}";
        }
    }
}

@mixin disable-prefix-for-all() {
    $prefix-for-webkit: false;
    $prefix-for-mozilla: false;
    $prefix-for-microsoft: false;
    $prefix-for-opera: false;
    $prefix-for-spec: false;
}

// = Border-radius
// -----------------------------
@mixin border-radius($radius: 5px) {
    @include prefixer(border-radius, $radius, moz spec)
}

@mixin border-top-radius($radius: 5px) {
    @include prefixer(border-top-left-radius, $radius, moz spec);
    @include prefixer(border-top-right-radius, $radius, moz spec);
}

@mixin border-bottom-radius($radius: 5px) {
    @include prefixer(border-bottom-left-radius, $radius, moz spec);
    @include prefixer(border-bottom-right-radius, $radius, moz spec);
}

@mixin border-left-radius($radius: 5px) {
    @include prefixer(border-top-left-radius, $radius, moz spec);
    @include prefixer(border-bottom-left-radius, $radius, moz spec);
}

@mixin border-right-radius($radius: 5px) {
    @include prefixer(border-top-right-radius, $radius, moz spec);
    @include prefixer(border-bottom-right-radius, $radius, moz spec);
}

// = Transition
// -----------------------------
@mixin transition ($transition: all .3s ease-in-out) {
    @include prefixer(transition, $transition, webkit moz ms o spec);
}

// = Box-sizing
// -----------------------------
@mixin box-sizing ($box: border-box) {
    //  content-box | border-box | inherit
    @include prefixer(box-sizing, $box, webkit moz spec);
}

// = Filter
// -----------------------------
@mixin filter ( $filter-1,
$filter-2: false,
$filter-3: false,
$filter-4: false,
$filter-5: false,
$filter-6: false,
$filter-7: false,
$filter-8: false,
$filter-9: false,
$filter-10: false) {
    $filter: compact($filter-1, $filter-2, $filter-3, $filter-4, $filter-5, $filter-6, $filter-7, $filter-8, $filter-9, $filter-10);
    @include prefixer(filter, $filter, webkit moz spec);
}

// = UL
// -----------------------------
@mixin ul-list-inline() {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        @include inline-block();
    }
}

@mixin reset-ul() {
    list-style: none;
    margin: 0;
    padding: 0;
}


// = Transform
// -----------------------------
@mixin transform($property: none) {
    @include prefixer(transform, $property, webkit moz ms o spec);
}

@mixin transform-origin($axes: 50%) {
    @include prefixer(transform-origin, $axes, webkit moz ms o spec);
}

@mixin transform-style ($style: flat) {
    @include prefixer(transform-style, $style, webkit moz ms o spec);
}

// = Keyframes
// -----------------------------
@mixin keyframes($name) {
    $original-prefix-for-webkit: $prefix-for-webkit;
    $original-prefix-for-mozilla: $prefix-for-mozilla;
    $original-prefix-for-microsoft: $prefix-for-microsoft;
    $original-prefix-for-opera: $prefix-for-opera;
    $original-prefix-for-spec: $prefix-for-spec;

    @if $original-prefix-for-webkit {
        @include disable-prefix-for-all();
        $prefix-for-webkit: true;
        @-webkit-keyframes #{$name} {
            @content;
        }
    }
    @if $original-prefix-for-mozilla {
        @include disable-prefix-for-all();
        $prefix-for-mozilla: true;
        @-moz-keyframes #{$name} {
            @content;
        }
    }
    @if $original-prefix-for-spec {
        @include disable-prefix-for-all();
        $prefix-for-spec: true;
        @keyframes #{$name} {
            @content;
        }
    }

    $prefix-for-webkit: $original-prefix-for-webkit;
    $prefix-for-mozilla: $original-prefix-for-mozilla;
    $prefix-for-microsoft: $original-prefix-for-microsoft;
    $prefix-for-opera: $original-prefix-for-opera;
    $prefix-for-spec: $original-prefix-for-spec;
}

// = Calc
// -----------------------------
@mixin calc($property, $expression) {
    #{$property}: -moz-calc(#{$expression});
    #{$property}: -o-calc(#{$expression});
    #{$property}: -webkit-calc(#{$expression});
    #{$property}: calc(#{$expression});
}