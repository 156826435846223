/* ==|====================
   Module/Headings
   ======================= */

%base-heading {
    font-family: $font1;
    font-weight: 200;
    color: $color1;
}


h1 {
    @extend %base-heading;
    font-size: 2.2em;
    line-height: 1;
    text-transform: uppercase;

    @media screen and (max-width: $width-tabletportrait) {
        font-size: 1.5em;
    }
}


h2 {
    @extend %base-heading;
    font-size: 2em;
    line-height: 100%; // text-transform: uppercase;

    @media screen and (max-width: $width-tabletportrait) {
        font-size: 1.2em;
    }
}


h3 {
    @extend %base-heading;
    text-transform: uppercase;

    font-size: 1.4em;
    line-height: 100%;

    @media screen and (max-width: $width-tabletportrait) {
        font-size: 1.2em;
    }
}


h4 {
    @extend %base-heading;
    font-size: 1.4em;
    line-height: 100%;

    @media screen and (max-width: $width-tabletportrait) {
        font-size: 1.2em;
    }
}