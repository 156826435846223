.MarkupPagerNav {
    font-family: $font1;
    display: inline-block;
    padding: 0;
}

.MarkupPagerNav li {
    display: inline-block;
    list-style: none;
    margin: 0;
}

.MarkupPagerNav li a,
.MarkupPagerNav li.MarkupPagerNavSeparator {

    display: block;
    float: left;
    padding: 2px 9px;
    color: $color1;
    background: rgb(237, 237, 237);
    margin-right: 3px;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
}

.MarkupPagerNav li.MarkupPagerNavOn a {
    color: #fff;
    background: $color2;
    text-decoration: none;
}

.MarkupPagerNav li a:hover {
    color: #fff;
    background: $color1;
    text-decoration: none;
}

.MarkupPagerNav li.MarkupPagerNavSeparator {
    display: inline;
    color: #777;
    background: #d2e4ea;
    padding-left: 3px;
    padding-right: 3px;
}