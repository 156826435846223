/* ======================
  Component / Praktische info
   ======================= */

.c-praktischeinfo {
    // background-color: $color3; // padding: 2em;
    font-size: 0.95em;
    margin-bottom: 0em;
    line-height: 1.8em;

    @media screen and (max-width: $width-smartphonelandscape) {
        margin-bottom: 1em;
    }

    &__uren {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        &>section {
            flex: 0 1 33%;

            @media screen and (max-width: $width-tabletlandscape) {
                flex: 0 1 100%;
            }
        }

        &>section>p {
            margin-top: 0;
            padding-right: 1em;
        }
    }

    h4 {
        display: flex;
        align-items: center;
        font-size: 1.1em;
        margin: 0;
        text-transform: uppercase;
        font-weight: 600;

        &>img {
            margin-right: 0.75em;
        }
    }

    h5 {
        font-weight: bold;
        font-size: 1.2em;
        text-transform: uppercase;
        margin-bottom: 0.5em;
    }

    &> {
        flex: 0 1 33%;
    }



    &>p {
        margin: 2em 0 0 0;
        font-weight: 600;
        padding-right: 1em;
        width: 70%;

        @media screen and (max-width: $width-smartphonelandscape) {
            margin: 1em 0;
            width: 100%;
        }
    }
}