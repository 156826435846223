/* ==|====================
   Layout/Nav
   ======================= */

.c-main-nav {
    &.cloned {
        position: fixed;
        z-index: 2000;
        top: 0;
        right: 0;
        width: 100%;
        background: white;
        @include transform(translateY(-100%));
        @include transition(all 300ms ease-in-out);

        & .c-hero-header__background {
            background-color: $color3;
        }

        .c-main-nav__second {
            & .current a {
                color: $color1;
            }
        }
    }

    &.sticked {
        @include transform(translateY(0%));

        & a {
            color: $color1;
        }

        .current {
            background-color: white;
        }

        .l-wrapper:first-child {
            background-color: #ededed;
        }
    }

    .current {
        background-color: white;

        & > a {
            color: $color1;
        }
    }

    a {
        color: white;
        font-size: 0.9em;
        text-transform: uppercase;
        font-weight: 600;
        padding: 0.75em 1em;
        display: inline-block;
        letter-spacing: 1px;

        @media screen and (max-width: $width-tabletlandscape) {
            padding: 0.75em 0.5em;
        }
    }

    &__first {
        flex: 1;

        @media screen and (max-width: $width-tabletlandscape) {
            display: none;
        }
    }

    &__social {
        // margin-right: 1em;

        & li {
            line-height: 0;
        }

        & li > a {
            // display:table-cell;
            vertical-align: middle;
        }

        & a {
            padding: 0 !important;
            margin: 0;
        }

        & i {
            display: block;
            line-height: 0;
        }

        @media screen and (max-width: $width-tabletlandscape) {
            display: none;
        }
    }

    &__language {
        text-align: right;

        @media screen and (max-width: $width-tabletlandscape) {
            display: none;
        }
    }

    &__second {
        @media screen and (max-width: $width-tabletlandscape) {
            display: none;
        }

        text-align: right;

        & > ul {
            padding: 0;
        }

        & a {
            color: white;
            padding: 0.5em 0;
            margin-right: 2em;
        }

        & > ul > li:last-child > a {
            padding-right: 0;
            margin-right: 0;
        }

        & > ul > li.current {
            background-color: transparent;

            & > a {
                color: white;
                border-bottom: 1px solid $color2;
            }
        }

        & a:hover {
            border-bottom: 1px solid white;
        }

        & .current a:hover {
            border-bottom: 0;
        }
    }
}

/*==========================================================================
   Menu Primary
   http://osvaldas.info/drop-down-navigation-responsive-and-touch-friendly
========================================================================== */

.l-nav__second.ul-list-inline {
    @include inline-block();
    float: left;
}

.l-nav__second .right {
    float: right;
    margin-right: 0;
    text-align: right;

    ul.sub-menu li {
        text-align: left;
    }
}

/* Eerste niveau van het menu */

ul.mainnav {
    display: block;
    text-transform: uppercase;
    padding: 0;
    font-size: 1.1em;

    @media screen and (max-width: 1100px) {
        font-size: 0.95em;
    }

    @media screen and (max-width: 945px) {
        font-size: 0.85em;
    }

    @media screen and (max-width: 768px) {
        font-size: 0.75em;
    }

    & li {
        @include inline-block();
        position: relative;
        z-index: 300;
        margin-right: 12px;
    }

    & li a {
        color: #000;
        font-size: 13px;
        line-height: 1.3;
        font-weight: 400;
        letter-spacing: 0.1em;
        font-family: 'Montserrat', Helvetica, Arial, sans-serif;
        text-transform: uppercase;
        display: block;
        color: $color-link;
        @include transition(all 0.2s ease-in-out 0s);
    }

    & > li > a {
        padding: 1.5em 0;
    }
}

ul.mainnav > li {
    width: auto;
    margin-right: 20px;
}

ul.mainnav > li:hover,
ul.mainnav > li.active {
    // border-bottom: 2px solid black;
}

ul.mainnav > li:last-child {
    margin-right: 0;
}

ul.mainnav li a:hover {
    color: $color-link-hover;
}

/* Tweede niveau van het menu*/

.mainnav ul {
    position: absolute;
    width: 300px;
    left: 0px;
    display: none;
    margin: 0;
    padding: 0;
}

ul.sub-menu {
    margin-left: 0px;
    padding-top: 10px;
}

ul.sub-menu ul {
    padding-left: 5px;
}

/* Bij mouseover tweede niveau tonen */

.mainnav li:hover > ul {
    display: block;
}

.mainnav ul li {
    display: block;
    float: left;
    background: white;
    margin: 0 0 5px 0;
    padding: 5px 0;
}

.mainnav ul li a {
    font-size: 0.85em;
    font-weight: normal;
    display: block;
    color: black;
    background: white;
    padding-left: 10px;
    padding-right: 20px;
}

.mainnav ul li a:hover,
.mainnav ul li:hover > a {
    background: none repeat scroll 0 0 $color-link-hover;
    color: white;
}

/* Derde niveau van het mnu */

.mainnav ul ul {
    left: 300px;
    top: 0px;
    padding-top: 0px;
}
