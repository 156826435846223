/* ==|====================
   Layout/Footer
   ======================= */

.c-footer {
    width: 100%;
    padding: 2em;
    text-align: center;

    @media screen and (max-width: $width-smartphonelandscape) {
        padding: 0 2em 2em 2em;
    }

    & > div:nth-child(2) {
        padding-top: 1em;
    }

    & .space-between {
        justify-content: space-between;
    }

    &--light {
        background: white;
        color: $color1;

        & .border {
            border-top: $color1 1px solid;
            padding-top: 1em;
        }
    }

    &--dark {
        background-color: $color1;
        color: white;

        & a {
            color: white;
            text-decoration: underline;
        }

        h4 {
            color: white;
            margin-top: 1.5em;
            margin-bottom: 0.5em;
            font-size: 1.2em;
        }

        & .border {
            border-top: white 1px solid;
            padding-top: 1em;
        }
    }

    &__social li {
        margin-right: 1em;
    }

    &__legal li {
        margin-left: 1em;
    }

    &__contact {
        flex: 0 1 100%;
        text-align: left;

        @media screen and (max-width: $width-tabletlandscape) {
            // flex: 0 1 40%;
        }

        @media screen and (max-width: $width-smartphonelandscape) {
            flex: 0 1 100%;
            text-align: center;
            font-size: 0.9em; // border-top: 1px solid $color1;
            padding: 0;
        }
    }

    &__message {
        position: fixed;
        z-index: 100;
        bottom: 0;
        width: 100%;
        color: white;
        padding: 1em;
        left: 0;
        margin: 0;
        background-color: $color1;

        /*=============================================
         =            CTA Brochure            =
         =============================================*/

        &--brochure {
            display: none;
            padding: 0;
            background: linear-gradient(to bottom right, #ed1e48, #f26b48);
            max-width: 1140px;
            margin: 0 calc((100% - 1140px) / 2);
            justify-content: space-between;
            align-items: center;
            opacity: 0;
            transition: opacity 0.5s ease-out, transform 0.5s ease-out;

            & a {
                flex: 0 0 auto;
                margin-right: 2em;
                text-decoration: none !important;
            }

            h3 {
                color: white;
                padding: 0;
                margin: 0;
            }

            figure {
                flex: 0 0 auto;
                line-height: 0;
            }

            img {
                width: 200px;
                height: 200px;
                object-fit: cover;
            }

            &.show {
                display: flex;
                opacity: 1;
                z-index: 1000;

                @media screen and (max-width: $width-tabletlandscape) {
                    display: none;
                }
            }

            button {
                display: inline-block;
                border: none;
                padding: 0.5rem 1rem;
                margin: 0;
                text-decoration: none;
                background: white;
                color: $color2;
                font-family: sans-serif;
                font-size: 1rem;
                cursor: pointer;
                text-align: center;
                transition: background 250ms ease-in-out, transform 150ms ease;
            }

            button:hover,
            button:focus {
                background: $color1;
                color: white;
            }

            button:focus {
                outline: 1px solid $color1;
                outline-offset: -4px;
            }
        }

        &--cataloguetext {
            text-align: left;
            display: flex;
            padding: 0 2em;
            flex-direction: column;
        }

        /*=====  End of Section comment block  ======*/

        @media screen and (max-width: $width-tabletlandscape) {
            display: none;
        }
    }

    &__logo {
        width: 200px;

        @media screen and (max-width: $width-smartphonelandscape) {
            display: none;
        }
    }

    &__about-us {
        display: none;
        flex: 0 1 50%;
        text-align: left;

        & p:first-child {
            margin-top: 0;
        }

        @media screen and (max-width: $width-smartphonelandscape) {
            display: none;
        }
    }
}
