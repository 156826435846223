/* Twins section */

.c-twin {
	padding: 2em;
	background: #2185C5;
	color: white;
	text-align: center;
}

.c-twin:last-of-type {
	background: #FF7F66;
}