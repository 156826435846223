.c-hero-header {

    &__navbg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 43px;
        z-index: 0;

        @media screen and (max-width: $width-tabletlandscape) {
            display: none;
        }
    }

    &__navbg {
        background: rgba(6, 6, 6, 0.5);
    }

    &__logo {
        flex: 1;

        @media screen and (max-width: $width-smartphonelandscape) {
            flex: unset;
        }

        & img {
            width: 250px;
            margin: 1.5em 0;
        }
    }

    &__blockquote {
        margin: 1em 0 0 0;
        padding: 0;
        width: 50%;
        font-size: 2.2em;
        color: white;
        line-height: 1.3em;

        @media screen and (max-width: $width-tabletportrait) {
            font-size: 1.8em;
        }

        @media screen and (max-width: $width-smartphonelandscape) {
            margin: 0 0 0 0;
            padding: 0;
            width: 100%;
            font-size: 1.1em;
        }
    }

    &__container {

        position: relative;
        z-index: 1;
        background-position: center;
        background-size: cover;
        clip-path: ellipse(150% 100% at 50% 0%);
        width: auto;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            background-image: url('https://www.huysman.be/site/assets/files/1/bg-header-3200x1200.1900x0.jpg');
        }

        @media screen and (min-width: $width-desktop) {
            height: 34em;
        }

        @media screen and (max-width: $width-desktop) {
            height: 30em;
        }

        @media screen and (max-width: $width-tabletlandscape) {
            height: 35em;
            padding: 0 1em;
        }

        @media screen and (max-width: $width-smartphonelandscape) {
            height: 25em;
        }
    }

    &__CTA-AXA {
        float: right;
        display: flex;
        height: 30px;
        line-height: 30px;
        padding-right: 1em;
        color: white;

        &>span {
            margin-right: 1em;
        }
    }
}

.cloned .c-hero-header__navbg {
    background-color: $color3;
}


// .heroimage {
//  background-image: linear-gradient(#ff9d2f, #ff6126);
//  clip-path: ellipse(100% 95% at 50% 0%);
// }